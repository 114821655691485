import { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import Style from "../../templates/event/event.style"

interface Props {
  quote: string
  name: string
  image: {
    title: string
    fluid: FluidObject
  }
}

const EventTestimonials: FunctionComponent<Props> = ({
  quote,
  image,
  name,
}) => (
  <Style.Testimonials.Testimonial.Container>
    <Style.Testimonials.Testimonial.Content.Container>
      <Style.Testimonials.Testimonial.Content.Quote>
        “{quote}”
      </Style.Testimonials.Testimonial.Content.Quote>
      <Style.Testimonials.Testimonial.Content.Name>
        - {name}
      </Style.Testimonials.Testimonial.Content.Name>
    </Style.Testimonials.Testimonial.Content.Container>
    {image && (
      <Style.Testimonials.Testimonial.Image
        fluid={image.fluid}
        alt={image.title}
      />
    )}
  </Style.Testimonials.Testimonial.Container>
)

export default EventTestimonials

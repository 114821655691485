import dayjs from "dayjs"
import { graphql, PageProps,navigate } from "gatsby"
import moment from "moment"
import React, { FunctionComponent, useCallback, useEffect, useRef } from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore no types yet
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc"
import Slider from "react-slick"
import Button from "../../components/common/button"
import CalendarModal from "../../components/common/calendarModal"
import { CTA } from "../../components/common/cta"
import SEO from "../../components/common/seo"
import ShareComponent from "../../components/common/ShareComponent"
import TickerStyle from "../../components/common/ticker.style"
import Ticker from "../../components/common/ticker"
import EventContributor from "../../components/event/eventContributor"
import EventTestimonials from "../../components/event/eventTestimonial"
import SubEvent from "../../components/event/subEvent"
import SubItem from "../../components/event/subItem"
import HashtagInstaFeed from "../../components/instafeed/hashtagInstaFeed"
import Layout from "../../components/layout/layout"
import { Event, stringifyDate } from "../../services/eventsService"
import { COLORS } from "../../styles"
import Style from "./event.style"
import { Media } from "../../Media"
import EventHeader from "./header"

interface Data {
  contentfulEvents: Event
  allContentfulEvents: { edges: { node: Event }[] }
  contentfulEventsPage: { slug: string }
}


//TODO: move this component to a separate file
const EventTickerCard: FunctionComponent<{ event: Event; filter: any }> = ({
  event,
  filter,
}) => {
  return (
    <Style.Ticker.TickerLikeElement>
      <Style.Ticker.EventLikeStartDate>
        <Style.Ticker.EventLikeStartDateComponents
          date={event.date}
          textColor={COLORS.HOLLY_GREEN}
        />
      </Style.Ticker.EventLikeStartDate>
      <Style.Ticker.link
        to={`/${location.pathname.substring(
          1,
          location.pathname.lastIndexOf("/")
        )}/${event.slug}`}
      >
        <TickerStyle.BottomCarouselImages.HighlightSpan />
        <TickerStyle.BottomCarouselImages.TickerImage
          fluid={event.image.fluid}
          alt={event.image.title}
        />
        <Style.Ticker.EventLikeEndDate $textColor={COLORS.HOLLY_GREEN}>
          {event.ongoing
            ? "ONGOING"
            : `Through ${event.endDate && stringifyDate(event.endDate)}`}
        </Style.Ticker.EventLikeEndDate>
        <Style.Ticker.EventLikeTickerTitle $textColor={COLORS.HOLLY_GREEN}>
          {event.title}
        </Style.Ticker.EventLikeTickerTitle>
      </Style.Ticker.link>
      <CTA
        to={`/${location.pathname.substring(
          1,
          location.pathname.lastIndexOf("/")
        )}/${event.slug}`}
        filter={filter}
        id="ctaId"
      >
        Learn More
      </CTA>
    </Style.Ticker.TickerLikeElement>
  )
}

const EventPage: FunctionComponent<PageProps<Data>> = (props) => {
  
  
  const { data, location } = props
  /* useEffect(()=>{
    data.contentfulEvents.slug == 'event' && navigate('/nyfw')
  },[]) */
  const { contentfulEvents: event } = data
  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    swipeToSlide: true,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
  }
  const testimonialsSliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    swipeToSlide: true,
    dots: true,
    arrows: false,
  }

  const {
    ticketLink,
    freeEvent,
    mondayStartingHours,
    tuesdayStartingHour,
    saturdayStartingHours,
    fridayStartingHours,
    sundayStartingHours,
    wednesdayStartingHours,
    thursdayStartingHours,
    date,
    endDate,
    image,
    content,
    fullContent,
    externalLink,
    title,
    keywords,
    locationAddress,
    eventContributors,
    eventContributorsTitle,
    eventContributorsSubtitle,
    eventHashtag,
    instagramSectionTitle,
    flagshipEvent,
    reservationEmail,
    reservationPhoneNumber,
    hasMultipleDates,
    linkToEventsDates,
    testimonials,
    ongoing,
    subeventItems,
    pdf,
  } = event

  const events = data.allContentfulEvents.edges.map((edge) => edge.node)
  const subEvents = events.filter(
    (item) => item.parentEvent !== null && item.parentEvent.slug === event.slug
  )

  function findCategory(element: string) {
    let found = false
    if (event.eventCategory) {
      for (let i = 0; i < event.eventCategory.length; i++) {
        if (event.eventCategory[i].title == element) {
          found = true
          break
        }
      }
    }
    return found
  }

  const getLikeEventsByCategories = useCallback(() => {
    const likeEventsByCategories = events.filter(
      (item) =>
        item.eventCategory &&
        item.eventCategory.some((element) => findCategory(element.title)) &&
        item.slug !== event.slug
    )
    if (likeEventsByCategories.length === 0) {
      return events.filter((item) => item.slug !== event.slug)
    }
    if (likeEventsByCategories.length <= 3) {
      return [
        ...likeEventsByCategories,
        ...events
          .filter((item) => item.slug !== event.slug)
          .slice(0, 3 - likeEventsByCategories.length),
      ]
    }
    return likeEventsByCategories
  }, [events, event])

  const AddToCalendarModal = AddToCalendarHOC(Button, CalendarModal)

  return (
    <Layout>
      <SEO
        title={title}
        description={content.content}
        keywords={keywords || ""}
      />
      <EventHeader
        title={title}
        image={image}
        content={content}
        flagshipEvent={flagshipEvent}
        ongoing={ongoing}
        date={date}
        hasMultipleDates={hasMultipleDates}
        endDate={endDate}
        pdf={pdf}
      />
      <Style.BackButton.Container>
        <Style.BackButton.Link
          $textColor={COLORS.HOLLY_GREEN}
          reverse
          to={`/${data.contentfulEventsPage.slug}`}
          state={location.state}
        >
          Back to Calendar
        </Style.BackButton.Link>
      </Style.BackButton.Container>
      {/*TODO: divide the code below into separate files components*/}
      {subEvents.length === 0 && !subeventItems && (
        <Style.Body.Background>
          <Style.Body.Hours>
            <Style.Body.When>WHEN</Style.Body.When>
            {ongoing ? (
              <Style.Body.Date>Ongoing</Style.Body.Date>
            ) : (
              <Style.Body.Date>
                {date && new Date() > new Date(date) && !hasMultipleDates ? (
                  <>
                    {"Now through "} {dayjs(endDate).format("MMMM D, YYYY")}
                  </>
                ) : hasMultipleDates && linkToEventsDates ? (
                  <>
                    Various Dates Until {dayjs(endDate).format("MMMM D, YYYY")}
                    <Style.Body.ReadMore to={linkToEventsDates}>
                      Learn More
                    </Style.Body.ReadMore>
                  </>
                ) : (
                  <>
                    From {dayjs(date).format("MMMM D, YYYY")} - To{" "}
                    {dayjs(endDate).format("MMMM D, YYYY")}
                  </>
                )}
              </Style.Body.Date>
            )}

            <Style.Body.Day $textColor={COLORS.HOLLY_GREEN}>
              {mondayStartingHours && (
                <Style.Body.Row>
                  <div>Monday</div>
                  <div>{mondayStartingHours}</div>
                </Style.Body.Row>
              )}
              {tuesdayStartingHour && (
                <Style.Body.Row>
                  <div>Tuesday</div>
                  <div>{tuesdayStartingHour}</div>
                </Style.Body.Row>
              )}
              {wednesdayStartingHours && (
                <Style.Body.Row>
                  <div>Wednesday</div>
                  <div>{wednesdayStartingHours}</div>
                </Style.Body.Row>
              )}
              {thursdayStartingHours && (
                <Style.Body.Row>
                  <div>Thursday</div>
                  <div>{thursdayStartingHours}</div>
                </Style.Body.Row>
              )}
              {fridayStartingHours && (
                <Style.Body.Row>
                  <div>Friday</div>
                  <div>{fridayStartingHours}</div>
                </Style.Body.Row>
              )}
              {saturdayStartingHours && (
                <Style.Body.Row>
                  <div>Saturday</div>
                  <>{saturdayStartingHours}</>
                </Style.Body.Row>
              )}
              {sundayStartingHours && (
                <Style.Body.Row>
                  <div>Sunday</div>
                  <div>{sundayStartingHours}</div>
                </Style.Body.Row>
              )}
            </Style.Body.Day>
            {locationAddress && (
              <>
                <Style.Body.Container>
                  <Style.Body.When>WHERE</Style.Body.When>
                  <Style.Body.Location>{locationAddress}</Style.Body.Location>
                  <Style.Body.ReadMore
                    to={
                      "https://www.google.com/maps/search/?api=1&query=" +
                      encodeURIComponent(locationAddress)
                    }
                  >
                    Get Directions
                  </Style.Body.ReadMore>
                </Style.Body.Container>
              </>
            )}
            {(reservationEmail || reservationPhoneNumber) && (
              <>
                <Style.Body.Container>
                  <Style.Body.When>RESERVATIONS</Style.Body.When>
                  {reservationEmail && (
                    <Style.Body.Date>
                      E:{" "}
                      <a href={`mailTo:${reservationEmail}`}>
                        {reservationEmail}
                      </a>
                    </Style.Body.Date>
                  )}
                  {reservationPhoneNumber && (
                    <Style.Body.Date>
                      T:{" "}
                      <a
                        href={`tel:${reservationPhoneNumber}`}
                        style={{ textDecoration: "none" }}
                      >
                        {reservationPhoneNumber}
                      </a>
                    </Style.Body.Date>
                  )}
                </Style.Body.Container>
              </>
            )}
            <Style.Body.Buy>
              {!freeEvent && ticketLink && (
                <Style.Body.BuyLink to={ticketLink} icon="ticket" mode="link">
                  Buy Tickets
                </Style.Body.BuyLink>
              )}
            </Style.Body.Buy>

            {freeEvent && !ongoing && (
              <AddToCalendarModal
                event={{
                  description: content.content,
                  duration: moment
                    .duration(
                      moment(endDate?.toString())
                        .utc()
                        .diff(moment(date?.toString()).utc())
                    )
                    .asDays(),
                  endDatetime: moment(endDate?.toString())
                    .utc()
                    .format("YYYYMMDDTHHmmssZ"),
                  location: locationAddress ? locationAddress : "fifth avenue",
                  startDatetime: moment(date?.toString())
                    .utc()
                    .format("YYYYMMDDTHHmmssZ"),
                  title: title,
                }}
                items={[SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]}
                buttonText="Add To Calendar"
                buttonProps={{ icon: "rightPlus" }}
              />
            )}

            <Style.Body.ShareComponent>
              <ShareComponent urlShare={location.href} />
            </Style.Body.ShareComponent>
          </Style.Body.Hours>
          <Style.Body.Container>
            <Style.Body.Title $textColor={COLORS.HOLLY_GREEN}>
              About the event
            </Style.Body.Title>
            {/* TODO: use markdownRendrer instead */}
            {fullContent && (
              <>
                <Style.Body.LongContent
                  $textColor={COLORS.HOLLY_GREEN}
                  dangerouslySetInnerHTML={{
                    __html: fullContent.childMarkdownRemark.html,
                  }}
                />
              </>
            )}
            {externalLink && (
              <Style.Body.ReadMore to={externalLink}>
                Visit site
              </Style.Body.ReadMore>
            )}
          </Style.Body.Container>
        </Style.Body.Background>
      )}
      {(subEvents.length !== 0 || !!subeventItems) && (
        <Style.SubEventsOuterContainer>
          <Style.SubEventsInnerContainer>
            {subEvents.map((subEvent) => (
              <SubEvent
                event={subEvent}
                eventBaseSlug={data.contentfulEventsPage.slug}
                filter={location.state?.filter}
                key={subEvent.slug}
              />
            ))}
            {event.subeventItems?.map((item) => (
              <SubItem item={item} key={item.id} />
            ))}
          </Style.SubEventsInnerContainer>
        </Style.SubEventsOuterContainer>
      )}
      {/*TODO: create EventContributer component in a separate file*/}
      {eventContributors && (
        <Style.EventContributors.Container>
          <Style.EventContributors.Title $textColor={COLORS.BOTTLE_GREEN}>
            {eventContributorsTitle}
          </Style.EventContributors.Title>
          <Style.EventContributors.Headline $textColor={COLORS.HOLLY_GREEN}>
            {eventContributorsSubtitle}
          </Style.EventContributors.Headline>
          <Media lessThan="md">
            <Slider {...sliderSettings}>
              {eventContributors.map((contributor, index) => (
                <EventContributor key={index} contributor={contributor} />
              ))}
            </Slider>
          </Media>
          <Media greaterThanOrEqual="md">
            <Style.EventContributors.Contributors>
              {eventContributors.map((contributor, index) => (
                <EventContributor key={index} contributor={contributor} />
              ))}
            </Style.EventContributors.Contributors>
          </Media>
        </Style.EventContributors.Container>
      )}
      {eventHashtag && (
        <HashtagInstaFeed
          color="white"
          hashtag={eventHashtag}
          experienceSharingTitle={instagramSectionTitle}
        />
      )}
      {testimonials && (
        <Style.Testimonials.Container>
          <Style.Testimonials.Title>
            WHAT PEOPLE ARE SAYING
          </Style.Testimonials.Title>
          <Slider {...testimonialsSliderSettings}>
            {testimonials.map(({ quote, image, name }, index) => (
              <EventTestimonials
                key={index}
                quote={quote.quote}
                image={image}
                name={name}
              />
            ))}
          </Slider>
        </Style.Testimonials.Container>
      )}
      <Style.Ticker.SectionLikeTitle>
        You may also like
      </Style.Ticker.SectionLikeTitle>
      <Style.Ticker.TickerLikeSection>
        <Ticker>
          {getLikeEventsByCategories().map((event, index) => (
            <EventTickerCard
              event={event}
              filter={location.state?.filter}
              key={`${event.slug}-${index}`}
            />
          ))}
        </Ticker>
      </Style.Ticker.TickerLikeSection>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allContentfulEvents {
      edges {
        node {
          id
          title
          date
          endDate
          slug
          customDate
          eventCategory {
            id
            title
          }
          parentEvent {
            title
            slug
          }
          subTitle
          content {
            content
          }
          fullContent {
            childMarkdownRemark {
              html
            }
          }
          image {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          carouselImages {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          externalLink
          ticketLink
          freeEvent
          ongoing
          locationAddress
          eventContributorsTitle
          parentEvent {
            id
            title
            slug
          }
        }
      }
    }
    contentfulEvents(slug: { eq: $slug }) {
      id
      title
      keywords
      date
      endDate
      customDate
      slug
      parentEvent {
        title
        slug
      }
      eventCategory {
        id
        title
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      fullContent {
        childMarkdownRemark {
          html
        }
      }
      image {
        title
        fluid(quality: 90, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      carouselImages {
        title
        fluid(quality: 90, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      parentEvent {
        id
        title
        slug
      }
      externalLink
      ticketLink
      freeEvent
      ongoing
      eventContributorsTitle
      eventContributorsSubtitle
      eventContributors {
        name
        description {
          description
        }
        image {
          title
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
        }
        readMore
      }
      mondayStartingHours
      tuesdayStartingHour
      saturdayStartingHours
      fridayStartingHours
      sundayStartingHours
      wednesdayStartingHours
      thursdayStartingHours
      locationAddress
      isFuture
      instagramSectionTitle
      eventHashtag
      flagshipEvent
      reservationEmail
      reservationPhoneNumber
      hasMultipleDates
      linkToEventsDates
      testimonials {
        quote
        name
        image {
          title
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
        }
      }
      subeventItems {
        title
        exactLocation {
          lat
          lon
        }
        carouselImages {
          id
          title
          fluid(quality: 90, maxWidth: 565) {
            ...GatsbyContentfulFluid
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
        ctaLink
        ctaText
        id
        subtitle
        when
        whenLink
        where
      }
      pdf {
        file {
          url
        }
      }
    }
    contentfulEventsPage {
      slug
    }
  }
`

export default EventPage
